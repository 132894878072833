import cuid from "cuid"

const timelineContent = [
  {
    id: cuid(),
    timelineTitle: `Before the Great Flood`,
    timelineTitleAcronym: `BGF`,
    events: [
      {
        id: cuid(),
        date: 10000,
        eventTitle: `Birth of Fey Races`,
        eventSubTitle: null,
        eventDetails: [``],
      },
      {
        id: cuid(),
        date: 3500,
        eventTitle: `Rise of Sirothell`,
        eventSubTitle: null,
        eventDetails: [``],
      },
      {
        id: cuid(),
        date: 3000,
        eventTitle: `Rise of Tarrest Nom`,
        eventSubTitle: null,
        eventDetails: [``],
      },
      {
        id: cuid(),
        date: 2000,
        eventTitle: `Aliak Oiv`,
        eventSubTitle: null,
        eventDetails: [``],
      },
      {
        id: cuid(),
        date: 1500,
        eventTitle: `Isad`,
        eventSubTitle: null,
        eventDetails: [``],
      },
      {
        id: cuid(),
        date: 1400,
        eventTitle: `Nogandul`,
        eventSubTitle: null,
        eventDetails: [``],
      },
      {
        id: cuid(),
        date: 677,
        eventTitle: `Rise of Odra Reg`,
        eventSubTitle: null,
        eventDetails: [`Odra Reg enters the realm of Yrub Silas`],
      },
      {
        id: cuid(),
        date: 420,
        eventTitle: `Attack on Sirothell`,
        eventSubTitle: null,
        eventDetails: [`Odra Reg and his vampire army attack Sirothell`],
      },
      {
        id: cuid(),
        date: 417,
        eventTitle: `Odra Reg Defeated`,
        eventSubTitle: null,
        eventDetails: [
          `The Moon Elf armies were able to defend their land and drive the Vampire Lord away.  This defeat was the first time Odra Reg was hurt from moonsilver.`,
        ],
      },
      {
        id: cuid(),
        date: 385,
        eventTitle: `Bostrazi`,
        eventSubTitle: null,
        eventDetails: [
          `A moon elf gene mutates with the vampire virus creating the first undead elves, the Bostrazi.  The Bostrazi organize and serve Odra Reg`,
        ],
      },
      {
        id: cuid(),
        date: 385,
        eventTitle: `Aliance between Odra Reg and Sol Enro`,
        eventSubTitle: null,
        eventDetails: [
          `The Death Knight Sol Enro strikes an alliance with Odra Reg to bring down Sirothell in exchange for the moon elf archives of magic and technology.`,
        ],
      },
      {
        id: cuid(),
        date: 385,
        eventTitle: `Fall of Sirothell`,
        eventSubTitle: null,
        eventDetails: [
          `The bostrazi, liches, undead warriors, and vampires form the the undead legion led by Sol Enro and Odra Reg and attack Sirothell.  The moon elves are no match for their combined power.  Sirothell falls in six days.  Many elves escape while the rest are enslaved. `,
        ],
      },
      {
        id: cuid(),
        date: 385,
        eventTitle: `Violite`,
        eventSubTitle: null,
        eventDetails: [
          `The dwarves and scientists of Tarrest Nom create the alloy Violite.`,
        ],
      },
      {
        id: cuid(),
        date: 250,
        eventTitle: `Vampire Wars`,
        eventSubTitle: null,
        eventDetails: [
          `The moon elf gene continues to mutate within the Bostrazi to create a foul beast.  Odra Reg launches an attack against all Bostrazi and abominations.`,
        ],
      },
    ],
  },
]

export default timelineContent
