import React from "react"
import PropTypes from "prop-types"
import Box from "@mui/material/Box"

import { StyledTimeline } from "./Timeline.styled"

import TimelineCard from "./TimelineCard"

import timelineContent from "./timeline.content"
import { Typography } from "@mui/material"

const Timeline = ({ children }) => {
  return (
    <StyledTimeline>
      <Box display="flex" justifyContent="center">
        <Box>
          {timelineContent.map(
            ({ id, timelineTitle, timelineTitleAcronym, events }) => (
              <Box key={id}>
                <Box mb={3}>
                  <Typography variant="h4">{timelineTitle}</Typography>
                </Box>
                <Box display="flex" justifyContent="center">
                  <TimelineCard
                    key={id}
                    timelineTitleAcronym={timelineTitleAcronym}
                    events={events}
                  />
                </Box>
              </Box>
            )
          )}
        </Box>
      </Box>
    </StyledTimeline>
  )
}

Timeline.propTypes = {
  children: PropTypes.node,
}

export default Timeline
