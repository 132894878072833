import React from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"

const TimelineCard = ({ id, timelineTitleAcronym, events }) => {
  return (
    <Box sx={{ maxWidth: 275 }} key={id}>
      {events.map(({ id, date, eventTitle, eventSubTitle, eventDetails }) => (
        <Box mb={4} key={id}>
          <Card variant="outlined">
            <CardContent>
              <Typography
                variant="h6"
                align="center"
              >{`${date} ${timelineTitleAcronym}`}</Typography>
              <Typography variant="h5" align="center" color="secondary">
                {eventTitle}
              </Typography>
              <Typography align="center">{eventSubTitle}</Typography>
              <Typography align="center" paragraph color="textSecondary">
                {eventDetails}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      ))}
    </Box>
  )
}

export default TimelineCard
